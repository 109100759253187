import { useSearchParams, Navigate } from "react-router-dom";
import { useGetModelAlreadyExists } from "../../apis/useGetModelAlreadyExists";
import { useGetPollProgress } from "../../apis/useGetPollProgress";
import LoadingScreenProgressBar from "../../components/loadingScreen/progressBar";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";
import { ContentContainer } from "../../components/ContentContainer/ContentContainer";
import { ContentArea } from "../../components/ContentArea/ContentArea";
import { DesignItProgress } from "@design-it/react-library";

export const AiComputingPage = () => {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const fileName = searchParams.get("fileName");
  const configName = searchParams.get("configName");

  const { data: modelExists, isLoading } = useGetModelAlreadyExists(
    configName ?? "config1"
  );
  const { data: pollProgress } = useGetPollProgress(jobId);

  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, []);

  if (pollProgress === 1) {
    if (modelExists) {
      return (
        <Navigate
          to={`/results?fileName=${fileName}&configName=${configName}`}
        />
      );
    }

    return (
      <Navigate
        to={`/labelling?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  return (
    <ContentContainer>
      <ContentArea>
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center flex-col" data-testid="progress">
            <DesignItProgress indeterminate />
          </div>
        ) : (
          <div className="flex h-96 flex-col items-center justify-center">
            <div data-testid="ai-computing-page"> 
              <LoadingScreenProgressBar value={pollProgress ?? 0} />
            </div>

            {Boolean(modelExists) && (
              <div data-testid="model-exists">
                Using your personalized AI model to analyze the data.
                <br />
                Results will be available shortly. Please wait.
              </div>
            )}

            {!modelExists && (
              <p data-testid="model-not-exists">
                Training a new AI model to analyze your data.
                <br />
                Results will be available shortly. Please wait.
              </p>
            )}
          </div>
        )}
      </ContentArea>
    </ContentContainer>
  );
};
