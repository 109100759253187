import { DesignItTag } from "@design-it/react-library";

export const AnomalyTagComponent = (props: { value: string }) => {
  if (props.value === "yes") {
    return (
      <DesignItTag
        className="ics-green-light"
        variant="error"
        icon-name="do_not_disturb_on"
        icon-position="first"
      >
        anomaly
      </DesignItTag>
    );
  }

  if (props.value === "no") {
    return (
      <DesignItTag
        className="ics-green-light"
        variant="success"
        icon-name="check_circle"
        icon-position="first"
      >
        no anomaly
      </DesignItTag>
    );
  }
  return null;
};
