import { createTheme } from "@mui/material/styles";

export const AutoCompleteTheme = createTheme({
  palette: {
    primary: {
      main: "#ff0000",
    },
    secondary: {
      main: "#00ff8c",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          color: "#ffffff",
          "&.Mui-focused": {
            color: "#ffffff",
            top: '15px',
          },
          '&.MuiFormLabel-filled': {
            top: '15px',
          },
          "&.Mui-disabled": {
            color: "white", 
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderBottom: "2px solid #ffffff", 
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none", 
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiAutocomplete-input": {
            color: "#ffffff", 
          },
          backgroundColor: "#00002C",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderBottom: "2px solid #ffffff", 
          borderRadius: "0",
          backgroundColor: "transparent",
          "&:hover": {
            borderBottom: "2px solid #ffffff",
          },
          "&.Mui-focused": {
            borderBottom: "2px solid #ff0000",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInput-root": {
            height: "60px",
            backgroundColor: "#00002C",
            color: "#ffffff",
            borderBottom: "2px solid #ffffff",
          },
          "& input": {
            paddingTop: "30px !important",
            paddingLeft: "15px !important",
            backgroundColor: "#00002C",
            borderRadius: "10px 10px 0 0",
          },
          "& .Mui-disabled": {
            backgroundColor: 'rgb(54, 54, 79)',
            borderRadius: "10px 10px 0 0",
            color: 'white',
          }
        },
        noOptions: {
          color: "white",
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        paper: {
          backgroundColor: "#00002C",
          color: "#ffffff",
          maxHeight: "3000px",
          overflowY: "auto",
        },
        option: {
          height: "90px",
          display: "flex",
          alignItems: "center",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#282840",
            color: "#ffffff",
          }
        },
        clearIndicator: {
          color: "#ffffff",
          backgroundColor: "#00002C",
          "&:hover": {
            color: "#ffffff",
            backgroundColor: "#282840",
          },
        },
        popupIndicator: {
          color: "#ffffff",
          backgroundColor: "#00002C",
          "&:hover": {
            color: "#ffffff",
            backgroundColor: "#282840",
          },
        },        
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#00002C",
          maxHeight: "200px",
        },
      },
    },
  },
});
