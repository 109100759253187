import { DesignItButton, DesignItInput } from "@design-it/react-library";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";
import { Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "./analysis.constants";
import { useEffect, useState } from "react";
import { useStepperContext } from "../../providers/StepperProvider";
import { Navigate } from "react-router-dom";

import { UploadDropContainer } from "../../components/uploadDropContainer/uploadDropContainer";
import { TabProps } from "./types";
import { createModelConfig as createModelConfigAPI } from "../upload/uploadHelpers";
import { useCSVContext } from "../../providers/CSVProvider";
type ErrorType = "empty" | false | "incorrect";
type NameErrorType = "empty" | "duplicate" | false | "incorrect";

interface InputErrors {
  toolId: ErrorType;
  processId: ErrorType;
  name: NameErrorType;
  torque: ErrorType;
  file: boolean;
}

export const PrepareAnalysis: React.FC<TabProps> = ({
  uploadContainerProps,
  toolId,
  configName,
  processId,
  targetTorque,
  targetAngle,
  setToolId,
  setConfigName,
  setProcessId,
  setTargetTorque,
  setTargetAngle,
  ...props
}) => {
  const { setStep, setShowStepper } = useStepperContext();
  const [inputErrors, setInputErrors] = useState<InputErrors>({
    toolId: false,
    processId: false,
    name: false,
    torque: false,
    file: false,
  });

  const { progress, setProgress, fileName, lines } = useCSVContext();

  useEffect(() => {
    setStep(1);
    setShowStepper(false);
  }, []);

  if (progress === 100 && lines.length > 0) {
    setProgress(0);

    return (
      <Navigate
        to={`/data-check?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  const evaluateErrors = () => {
    const newInputErrors = {
      processId: (inputErrors.processId === "incorrect"
        ? "incorrect"
        : processId === ""
          ? "empty"
          : false) as ErrorType,
      name: (inputErrors.name === "incorrect"
        ? "incorrect"
        : configName === ""
          ? "empty"
          : configName in props.modelConfigs
            ? "duplicate"
            : false) as NameErrorType,
      torque: (inputErrors.torque === "incorrect"
        ? "incorrect"
        : targetTorque === ""
          ? "empty"
          : false) as ErrorType,
      toolId: (inputErrors.toolId === "incorrect"
        ? "incorrect"
        : toolId === ""
          ? "empty"
          : false) as ErrorType,
      file: !props.file,
    };

    return newInputErrors;
  };

  const verifyUploadAndTrigger = () => {
    const newInputErrors = evaluateErrors();

    setInputErrors(newInputErrors);
    const hasErrors = Object.values(newInputErrors).some(error => error);

    if (!hasErrors) {
      createModelConfig();
      props.handleUpload();
    }
  };

  const createModelConfig = () => {
    createModelConfigAPI({
      tool_id: toolId,
      model_name: configName,
      process_id: processId,
      target_torque: targetTorque,
      target_angle: targetAngle,
    });
  };

  const setErrorText = (inputType: string): string => {
    switch (inputType) {
      case "processId": {
        if (inputErrors.processId === "empty") {
          return "This is a mandatory field";
        } else {
          return "Please enter a valid Process ID (letters and numbers only + underscore)";
        }
        break;
      }
      case "name": {
        if (inputErrors.name === "empty") {
          return "This is a mandatory field";
        } else if (inputErrors.name === "duplicate") {
          return "This name is already taken. Choose a different one.";
        } else {
          return "Please enter a valid name (letters and numbers only + underscore). Maximum 30 characters";
        }
        break;
      }
      case "torque": {
        if (inputErrors.torque === "empty") {
          return "This is a mandatory field";
        } else {
          return "Please enter a valid Target Torque (letters and numbers only + underscore)";
        }
        break;
      }
      case "toolId": {
        if (inputErrors.toolId === "empty") {
          return "This is a mandatory field";
        } else {
          return "Please enter a valid Tool-ID (letters and numbers only + underscore)";
        }
      }
      default: {
        return "";
      }
    }
  };

  const evaluateOnInput = (
    event: React.FormEvent<HTMLDesignItInputElement>,
    inputType: string
  ) => {
    const target = event.target as HTMLInputElement;

    switch (inputType) {
      case "processId": {
        setProcessId(target.value || "");

        if (!target.checkValidity()) {
          setInputErrors({ ...inputErrors, processId: "incorrect" });
        } else {
          setInputErrors({ ...inputErrors, processId: false });
        }
        break;
      } // Evaluate process ID
      case "name": {
        setConfigName(target.value || "");

        if (!target.checkValidity()) {
          setInputErrors({ ...inputErrors, name: "incorrect" });
        } else {
          setInputErrors({ ...inputErrors, name: false });
        }
        break;
      } // Evaluate name
      case "torque": {
        setTargetTorque(target.value || "");

        if (!target.checkValidity()) {
          setInputErrors({ ...inputErrors, torque: "incorrect" });
        } else {
          setInputErrors({ ...inputErrors, torque: false });
        }
        break;
      } // Evaluate torque
      case "toolId": {
        setToolId(target.value || "");

        if (!target.checkValidity()) {
          setInputErrors({ ...inputErrors, toolId: "incorrect" });
        } else {
          setInputErrors({ ...inputErrors, toolId: false });
        }
        break;
      } // Evaluate torque
      case "file": {
        break;
      } // Evaluate file
    }
  };

  return (
    <div className="flex w-[100%] flex-col">
      <div className="flex w-[100%] h-full">
        <div className="w-[50%] pt-[50px] pb-[50px] pl-[124px] pr-[124px]">
          <Typography>1. Define your analysis job</Typography>

          <div className="flex gap-8 flex-col">
            <DesignItInput
              className="full-input"
              label="Tool-ID"
              type="text"
              variant="filled"
              value={toolId}
              onInput={(event: React.FormEvent<HTMLDesignItInputElement>) => {
                evaluateOnInput(event, "toolId");
              }}
              error={
                inputErrors.toolId === "empty" ||
                inputErrors.toolId === "incorrect"
              }
              errorText={setErrorText("toolId")}
              required
              pattern={"[A-Za-z0-9\\s_]+"}
            />
            <DesignItInput
              className="full-input"
              label="Process ID"
              type="text"
              variant="filled"
              value={processId}
              onInput={(event: React.FormEvent<HTMLDesignItInputElement>) => {
                evaluateOnInput(event, "processId");
              }}
              error={
                inputErrors.processId === "empty" ||
                inputErrors.processId === "incorrect"
              }
              errorText={setErrorText("processId")}
              required
              pattern={"[A-Za-z0-9\\s_]+"}
            />

            <DesignItInput
              className="full-input"
              label="Name your analysis job"
              type="text"
              variant="filled"
              value={configName}
              onInput={(event: React.FormEvent<HTMLDesignItInputElement>) => {
                evaluateOnInput(event, "name");
              }}
              error={
                inputErrors.name === "empty" ||
                inputErrors.name === "duplicate" ||
                inputErrors.name === "incorrect"
              }
              errorText={setErrorText("name")}
              pattern={"[A-Za-z0-9\\s_]{1,30}"}
              required
            />
            <div className="flex justify-between">
              <div className="w-1/2  mr-1">
                <DesignItInput
                  className="full-input"
                  label="Target torque"
                  type="text"
                  variant="filled"
                  value={targetTorque}
                  onInput={(
                    event: React.FormEvent<HTMLDesignItInputElement>
                  ) => {
                    evaluateOnInput(event, "torque");
                  }}
                  error={
                    inputErrors.torque === "empty" ||
                    inputErrors.torque === "incorrect"
                  }
                  errorText={setErrorText("torque")}
                  required
                  pattern={"[A-Za-z0-9\\s_.,]+"}
                />
              </div>
              <div className="w-1/2 ml-1">
                <DesignItInput
                  className="full-input"
                  label="Target angle"
                  type="text"
                  variant="filled"
                  value={targetAngle || ""}
                  onInput={(
                    event: React.FormEvent<HTMLDesignItInputElement>
                  ) => {
                    const target = event.target as HTMLInputElement;
                    setTargetAngle(target.value);
                  }}
                />
                <p className="mt-[5px] pl-2">Optional</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[50%] pt-[50px] pb-[50px] pl-[124px] pr-[124px] h-full ">
          <div className="mb-5 text-lg">
            <Typography className="mb-5 text-lg">
              <span className="inline">
                2. Upload a training file. Use this &nbsp;
              </span>
              <CopyToClipboard
                className="inline"
                text={CLIPBOARD_TEXT}
                label={"format "}
              />
              <Tooltip title={TOOLTIP_TEXT}>
                <HelpOutlineIcon fontSize={"small"} />
              </Tooltip>
            </Typography>
          </div>

          <UploadDropContainer
            {...uploadContainerProps}
            hasError={inputErrors.file}
          ></UploadDropContainer>

          <div className="flex my-10 mx-96">
            <DesignItButton
              variant="filled"
              disabled={
                inputErrors.name !== false ||
                configName == "" ||
                inputErrors.processId !== false ||
                processId == "" ||
                inputErrors.torque !== false ||
                targetTorque == "" ||
                !props.file ||
                inputErrors.toolId !== false ||
                toolId == ""
              }
              onClick={verifyUploadAndTrigger}
              data-testid="prepare-analysis-button"
            >
              Let&apos;s go
            </DesignItButton>
          </div>
        </div>
      </div>
    </div>
  );
};
