import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';

export const baseURL = '';

const httpRequest = axios.create({
  baseURL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

const requestInterceptor = async (request: InternalAxiosRequestConfig) => {
  const user = await fetchAuthSession();
  const token = user.tokens?.idToken?.toString();

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

const resInterceptor = (response: AxiosResponse) => {
  if (response.headers?.['content-disposition']) {
    return response;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data;
};

httpRequest.interceptors.response.use(resInterceptor);
httpRequest.interceptors.request.use(requestInterceptor);

export default httpRequest;
