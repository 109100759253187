export const columnMapper = (element: { [key: string]: string }) => {
  const getArrayFromString = (str: string) =>
    typeof str === "string" ? str.split(";").map(parseFloat) : [];

  const torque = getArrayFromString(element["torqueValues"]);
  const angle = getArrayFromString(element["angleValues"]);
  const time = getArrayFromString(element["timeValues"]);

  const result = { angleValues: angle, torqueValues: torque, timeValues: time };
  return result;
};
