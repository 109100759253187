import { createContext, useContext, PropsWithChildren, useState } from "react";

export type InitStateType = {
  showStepper: boolean;
  setShowStepper: (flag: boolean) => void;
  step: number;
  setStep: (step: number) => void;
  isExecuteTabAnalysis: boolean;
  setIsExecuteTabAnalysis: (flag: boolean) => void;
};

const initState: InitStateType = {
  showStepper: true,
  setShowStepper: () => {},
  step: 1,
  setStep: () => {},
  isExecuteTabAnalysis: false,
  setIsExecuteTabAnalysis: () => {},
};

const StepperContext = createContext(initState);

export const useStepperContext = () => {
  const context = useContext(StepperContext);

  return context;
};

export const StepperProvider = ({ children }: PropsWithChildren) => {
  const [step, setStep] = useState<number>(initState.step);
  const [showStepper, setShowStepper] = useState<boolean>(
    initState.showStepper
  );
  const [isExecuteTabAnalysis, setIsExecuteTabAnalysis] = useState<boolean>(
    initState.isExecuteTabAnalysis
  );

  return (
    <StepperContext.Provider
      value={{
        step,
        setStep,
        showStepper,
        setShowStepper,
        isExecuteTabAnalysis,
        setIsExecuteTabAnalysis,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
