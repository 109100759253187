import NormalizedGraph from "../../components/normalizedGraph/graph";
import {
  DesignItButton,
  DesignItCard,
  DesignItProgress,
} from "@design-it/react-library";
import moment from "moment";
import * as Amplify from "@aws-amplify/auth";
import { apiHelper } from "../../apis/apiHelper";
import { updateUploadCount } from "../../utils/uploadLimitHelpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Navigate } from "react-router";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";
import { useCSVContext } from "../../providers/CSVProvider";
import {
  downloadFromPresignedUrl,
  fetchPresignedUrl,
} from "../upload/uploadHelpers";
import { columnMapper } from "../dashboard/uploadHelper";
import { ContentContainer } from "../../components/ContentContainer/ContentContainer";
import { ContentArea } from "../../components/ContentArea/ContentArea";
import { ButtonBar } from "../../components/ButtonBar/ButtonBar";
import { Stepper } from "../dashboard/Stepper";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";

export const DataCheckPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fileName = searchParams.get("fileName");
  const lastStep = searchParams.get("lastStep");
  const configName = searchParams.get("configName");

  const { isExecuteTabAnalysis } = useStepperContext();

  const { lines, setLines } = useCSVContext();

  useEffect(() => {
    async function loadData() {
      if (!lines.length) {
        const preSignedUrl = await fetchPresignedUrl(fileName as string);
        const jsonData = await downloadFromPresignedUrl(preSignedUrl);
        const fileLines = jsonData.map(columnMapper);
        setLines(fileLines);
      }
    }
    loadData();
  }, []);

  if (!fileName) {
    return <Navigate to={"/analysis"} />;
  }

  const onContinueClick = async () => {
    if (lastStep === "results") {
      navigate(`/results?fileName=${fileName}`);
    } else if (lastStep === "labelling") {
      navigate(`/labelling?fileName=${fileName}`);
    } else {
      const jobId = moment().format("YYYY-MM-DD_HH:mm:ss.SSS");
      const userId = (await Amplify.getCurrentUser())["userId"];
      const uploadBucketName =
        process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";

      apiHelper.post(
        process.env.REACT_APP_REST_API_ROOT_URL + "/start-workflow",
        {
          "user-id": userId,
          "bucket-name": uploadBucketName,
          "file-key": `raw/${fileName}`,
          "job-id": jobId,
          "config-name": `${configName}`,
        }
      );

      updateUploadCount(1, userId);
      navigate(
        `/ai-computing?jobId=${jobId}&fileName=${fileName}&configName=${configName}`
      );
    }
  };

  return (
    <ContentContainer>
      <ContentArea>
        <div className="h-[10%]">
          <div className="py-5 text-3xl">
            Validate the uploaded curves for training.
          </div>
        </div>
        <div className="h-[90%]">
          <div className="px-5 h-[20%]">
            <Stepper
              steps={
                isExecuteTabAnalysis
                  ? MLFlowStepperLabelsExecuteTab
                  : MLFlowStepperLabelsAnalysisTab
              }
              currentStep={2}
              shouldShowStepper={true}
            />
          </div>
          <div className="h-[80%]">
            <DesignItCard
              state={undefined}
              className="bg-surface h-full w-full max-w-full max-h-full min-w-full min-h-full"
            >
              {lines.length === 0 ? (
                <div className="w-full h-full flex justify-center items-center flex-col">
                  <DesignItProgress indeterminate />
                  <p>Building Graph...</p>
                </div>
              ) : (
                <NormalizedGraph lines={lines} />
              )}
            </DesignItCard>
          </div>
        </div>
      </ContentArea>
      <div className="footer-buttons h-[5%]">
        <ButtonBar>
          <DesignItButton onClick={() => onContinueClick()}>
            Continue
          </DesignItButton>
        </ButtonBar>
      </div>
    </ContentContainer>
  );
};
