import React, { useEffect } from "react";
import { useStepperContext } from "../../providers/StepperProvider";
import "@design-it/web-core/dist/assets/themes/themes.scss";
import "./login.scss";

interface ErrorProps {
  setShowSideMenu: (isLoginPage: boolean) => void;
}

const Error: React.FC<ErrorProps> = ({ ...props }) => {
  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    props.setShowSideMenu(false);
    setShowStepper(false);
  }, []);

  return (
    <div>
      <div className="justify-center flex flex-col items-center">
        <span className="text-3xl text-white pt-5 pb-5">Page not found</span>
        <img
          src={require("../../assets/images/error.webp")}
          className="img max-w-xl"
        />
        <span className="text-xl text-white pt-5 pb-5">
          Uh-oh, you&apos;ve encountered a different kind of anomaly - this
          probably isn&apos;t the page you&apos;re looking for.
        </span>
      </div>
    </div>
  );
};

export default Error;
