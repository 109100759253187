import { DesignItButton } from "@design-it/react-library";
import { Autocomplete, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "./analysis.constants";
import { useStepperContext } from "../../providers/StepperProvider";
import { Navigate } from "react-router-dom";
import { UploadDropContainer } from "../../components/uploadDropContainer/uploadDropContainer";
import { TabProps } from "./types";
import { useCSVContext } from "../../providers/CSVProvider";
import { useModelConfigs } from "./UseModelConfigs";
import { Option } from "./AnalysisHelpers";

export const getPids = (options: Option[], selectedToolId: string) => {
  return selectedToolId
    ? options
      .filter(option => option.tool_id === selectedToolId)
      .map(option => option.process_id)
    : [];
};

export const getAnalysisNames = (options: Option[], selectedPid: string) => {
  return selectedPid
    ? options
      .filter(option => option.process_id === selectedPid)
      .map(option => option.name)
    : [];
};

export const getTorques = (options: Option[], selectedAnalysisName: string) => {
  return selectedAnalysisName
    ? options
      .filter(o => o.name === selectedAnalysisName)
      .map(o => o.target_torque)
    : [];
};

export const getAngles = (
  options: Option[],
  selectedAnalysisName: string,
  selectedTorque: string
) => {
  return selectedTorque
    ? options
      .filter(o => o.target_angle)
      .filter(o => o.name === selectedAnalysisName)
      .map(o => o.target_angle)
    : [];
};

export const ExecuteAnalysis: React.FC<TabProps> = ({
  uploadContainerProps,
  ...props
}) => {
  const { setStep, setShowStepper } = useStepperContext();
  const { progress, setProgress, fileName, lines } = useCSVContext();

  useEffect(() => {
    setStep(1);
    setShowStepper(true);
    localStorage.removeItem("fileLines");
    localStorage.removeItem("lines");
  }, []);

  const { loading, options, toolIds } = useModelConfigs(props.modelConfigs);

  const [selectedToolId, setSelectedToolId] = useState<string | null>(null);
  const [selectedPid, setSelectedPid] = useState<string | null>(null);
  const [selectedAnalysisName, setSelectedAnalysisName] = useState<
    string | null
  >(null);
  const [selectedTorque, setSelectedTorque] = useState<string | null>(null);
  const [selectedAngles, setSelectedAngles] = useState<string | null>(null);

  const pids = useMemo(
    () => getPids(options, selectedToolId || ""),
    [selectedToolId, options]
  );

  const analysisNames = useMemo(
    () => getAnalysisNames(options, selectedPid || ""),
    [selectedPid, options]
  );

  const torques = useMemo(
    () => getTorques(options, selectedAnalysisName || ""),
    [options, selectedAnalysisName]
  );

  const angles = useMemo(
    () => getAngles(options, selectedAnalysisName || "", selectedTorque || ""),
    [options, selectedTorque, selectedAnalysisName]
  );

  useEffect(() => {
    if (!selectedToolId) {
      setSelectedPid(null);
      setSelectedAnalysisName(null);
      setSelectedTorque(null);
      setSelectedAngles(null);
    }
  }, [selectedToolId]);

  useEffect(() => {
    setSelectedAnalysisName(null);
    setSelectedTorque(null);
    setSelectedAngles(null);
  }, [selectedPid]);

  useEffect(() => {
    setSelectedTorque(null);
    setSelectedAngles(null);
  }, [selectedAnalysisName]);

  useEffect(() => {
    setSelectedAngles(null);
  }, [selectedTorque]);

  const onHandleUploadClick = () => {
    if (
      selectedToolId &&
      selectedAnalysisName &&
      selectedPid &&
      selectedTorque
    ) {
      props.handleUpload();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (progress === 100 && lines.length > 0) {
    setProgress(0);
    return (
      <Navigate
        to={`/data-check?fileName=${fileName}&configName=${selectedAnalysisName}`}
      />
    );
  }

  return (
    <div className="flex flex-col w-full p-3">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="flex flex-col justify-center">
          <div className="mt-16 mb-16 mr-20 ml-20">
            <Typography>1. Choose an analysis job</Typography>
            <div className="flex flex-col gap-4 mt-8">
              <div className="w-full ">
                <Autocomplete
                  options={toolIds}
                  value={selectedToolId}
                  onChange={(_, value) => setSelectedToolId(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Tool ID'}
                      sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
                    />
                  )}
                />
              </div>
              <div className="w-full mt-5">
                <Autocomplete
                  options={pids}
                  value={selectedPid}
                  disabled={!selectedToolId}
                  onChange={(_, value) => setSelectedPid(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Process ID'}
                      sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
                    />
                  )}
                />
              </div>
              <div className="w-full mt-5">
                <Autocomplete
                  options={analysisNames}
                  disabled={!selectedPid || !selectedToolId}
                  value={selectedAnalysisName}
                  onChange={(_, value) => setSelectedAnalysisName(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Name your analysis job'}
                      sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
                    />
                  )}
                />
              </div>
              <div className="w-full mt-5 flex flex-row gap-5">
                <div className="flex-1">
                  <Autocomplete
                    options={torques}
                    disabled={
                      !selectedAnalysisName ||
                      !selectedPid ||
                      !selectedToolId
                    }
                    value={selectedTorque}
                    onChange={(_, value) => setSelectedTorque(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={'Target torque'}
                        sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
                      />
                    )}
                  />
                </div>
                <div className="flex-1">
                  <Autocomplete
                    options={angles}
                    disabled={
                      !selectedTorque ||
                      !selectedAnalysisName ||
                      !selectedPid ||
                      !selectedToolId
                    }
                    value={selectedAngles}
                    onChange={(_, value) => setSelectedAngles(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={'Target angle'}
                        sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
                      />
                    )}
                  />
                  <p className="mt-2 text-white-500">Optional</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col p-4 m-auto justify-center ">
          <p className="mb-5 text-lg">
            <Typography className="inline">
              <span className="inline ml-5">
                2. Upload an analysis file. Use this &nbsp;
              </span>
              <CopyToClipboard
                className="inline"
                text={CLIPBOARD_TEXT}
                label={"format "}
              />
              <Tooltip title={TOOLTIP_TEXT}>
                <HelpOutlineIcon fontSize={"small"} />
              </Tooltip>
            </Typography>
          </p>
          <div className="mt-4">
            <UploadDropContainer {...uploadContainerProps} />
          </div>
        </div>
      </div>

      <div className="flex justify-end mr-24">
        <DesignItButton
          variant="filled"
          onClick={() => onHandleUploadClick()}
          disabled={
            !selectedToolId ||
            !selectedAnalysisName ||
            !selectedPid ||
            !selectedTorque
          }
        >
          Let&apos;s go
        </DesignItButton>
      </div>
    </div>
  );
};
