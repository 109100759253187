export const MLFlowStepperLabelsAnalysisTab: string[] = [
  'Configuration',
  'Validation',
  'Feedback'
];

export const MLFlowStepperLabelsExecuteTab: string[] = [
  'Configuration',
  'Validation',
  'Results'
];
