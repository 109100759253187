import { getCurrentUser } from "@aws-amplify/auth";
import { apiHelper, ApiResponse } from "../apis/apiHelper";
import AWS from "aws-sdk";

export async function updateUploadCount(
  uploadCount: number = 1,
  userId?: string
) {
  try {
    if (!userId) {
      userId = (await getCurrentUser())["userId"];
    }

    const dynamodb = new AWS.DynamoDB.DocumentClient({
      region: process.env.REACT_APP_REGION,
    });

    const checkParams = {
      TableName: process.env.REACT_APP_UPLOAD_COUNT_TABLE_NAME || "",
      Key: {
        user_id: userId,
      },
    };

    const data = await dynamodb.get(checkParams).promise();

    if (data.Item) {
      const curUploadCount = data.Item["count"];

      const updateParams = {
        TableName: process.env.REACT_APP_UPLOAD_COUNT_TABLE_NAME || "",
        Key: {
          user_id: userId,
        },
        UpdateExpression: "set #count = :newCount",
        ExpressionAttributeValues: {
          ":newCount": curUploadCount + uploadCount,
        },
        ExpressionAttributeNames: {
          "#count": "count",
        },
      };

      await dynamodb.update(updateParams).promise();
    }
  } catch (error) {
    console.log("Error when updating upload count: ", error);
  }
}

export async function userCanUpload() {
  try {
    const user_id = (await getCurrentUser())["userId"];

    const url =
      process.env.REACT_APP_REST_API_ROOT_URL +
      "/configurations/get-upload-count";

    const response: ApiResponse = await apiHelper.post(url, {
      user_id,
    });

    const uploadCount = (response["body"] as Record<string, unknown>)[
      "count"
    ] as number;

    if (uploadCount >= 100) {
      return false;
    }

    return true;
  } catch (err) {
    console.log("Error when checking whether user can upload");
    return false;
  }
}

export async function getPreProccessData() {
  try {
    const dynamodb = new AWS.DynamoDB.DocumentClient({
      region: process.env.REACT_APP_REGION,
    });

    const checkParams = {
      TableName: "preprocessed",
    };

    const data = await dynamodb.query(checkParams).promise();
    if (data && data?.Items?.length !== 0) {
      return data.Items;
    } else {
      return [];
    }
  } catch (error) {
    console.log("Error when updating upload count: ", error);
    return [];
  }
}

export async function getBoltingOperationData(
  user_id: string,
  last_evaluated_key: string
) {
  try {
    const dynamodb = new AWS.DynamoDB.DocumentClient({
      region: process.env.REACT_APP_REGION,
    });

    const checkParams = {
      TableName: "bolting-operations",
      IndexName: "user_id-index",
      KeyConditionExpression: "user_id = :val",
      ExpressionAttributeValues: { ":val": user_id },
      LastEvaluatedKey: last_evaluated_key,
    };

    const data = await dynamodb.query(checkParams).promise();

    if (data && data?.Items?.length !== 0) {
      return [data.Items, data.LastEvaluatedKey];
    } else {
      return [];
    }
  } catch (error) {
    console.log("Error when fetching bolting operation data: ", error);
    return [];
  }
}

export async function getBoltingOperationDataSize(user_id: string) {
  try {
    const url =
      "https://ghokvtd8oj.execute-api.eu-west-1.amazonaws.com/default/get-bolting-operation-size";
    const response = await apiHelper.post(url, {
      user_id: user_id,
      table_name: "bolting-operations",
    });
    const size = JSON.parse(response["body"] as string)["total_count"];

    return size;
  } catch (error) {
    console.log("Error: ", error);
  }
}

export async function fetchUserFileConfigurations(
  configurationName: string = ""
) {
  try {
    const user_id = (await getCurrentUser())["userId"];

    const url =
      process.env.REACT_APP_REST_API_ROOT_URL + "/configurations/get-configs";

    const response = await apiHelper.post(url, {
      user_id,
      configurationName,
    });

    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
}

export function convertEpochToFormattedTime(epochTime: number) {
  const date = new Date(epochTime);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;

  return formattedTime;
}

export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));
