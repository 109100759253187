type CopyToClipboardProps = {
  text: string;
  label: string;
  className?: string;
};

export const CopyToClipboard = ({ text, label }: CopyToClipboardProps) => {
  return (
    <span
      className={`text-primary cursor-pointer`}
      onClick={() => navigator.clipboard.writeText(text)}
    >
      {label}
    </span>
  );
};
