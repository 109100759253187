import { PropsWithChildren } from "react";

type PageContainerProps = {
  isLoginPage?: boolean;
};

export const PageContainer = ({
  children,
  isLoginPage,
}: PropsWithChildren<PageContainerProps>) => {
  const loginPageContainerClassNames = !isLoginPage
    ? "rounded-xl min-h-min"
    : "";

  return (
    <div className={`h-full ${loginPageContainerClassNames}`}>{children}</div>
  );
};
