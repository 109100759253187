// Define the initial state of the counter
interface APPState {
  value: number;
  logout: boolean;
}

const initialState: APPState = {
  value: 0,
  logout: false,
};

// Define action types as string constants
export const LOGOUT = "LOGOUT";

interface LogoutAction {
  type: typeof LOGOUT;
}

export const logout = () => ({
  type: LOGOUT,
});

// Combine the action interfaces for use with the reducer
type AppActionTypes = LogoutAction;

// The reducer function
export default function counterReducer(
  state = initialState,
  action: AppActionTypes
): APPState {
  switch (action.type) {
    case LOGOUT:
      return { ...state, logout: false };
    default:
      return state;
  }
}
