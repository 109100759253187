import * as Amplify from "@aws-amplify/auth";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { apiHelper } from "./apiHelper";

const getModelAlreadyExists = async (configName: string): Promise<boolean> => {
  const user_id = (await Amplify.getCurrentUser())["userId"];
  const url = process.env.REACT_APP_REST_API_ROOT_URL + "/model/model-exists";

  const response = await apiHelper.post(url, {
    user_id,
    config_name: configName,
  });

  const exists = (response.body as { exists: boolean }).exists;

  return exists;
};

export const useGetModelAlreadyExists = (
  configName: string,
  options?: UseQueryOptions<boolean>
) =>
  useQuery({
    queryKey: ["use-get-model-already-exists"],
    queryFn: () => getModelAlreadyExists(configName),
    ...options,
  });
