import { DesignItButton, DesignItDialog } from "@design-it/react-library";
import LoadingScreenProgressBar from "../loadingScreen/progressBar";
import "./style.scss";
import { memo } from "react";

type UploadDialogProps = {
  uploadProgress: number;
  handleCancelUpload: () => void;
};

function UploadDialog({
  uploadProgress,
  handleCancelUpload
}: UploadDialogProps) {
  const innerCancelCallback = () => {
    if (uploadProgress !== 100) {
      handleCancelUpload();
    }
  };

  return (
    <DesignItDialog open={true} className='upload-dialog'>
      <div slot='headline' className='flex justify-center'>
        Your file is uploading
      </div>
      <div slot='content' className='flex justify-center'>
        <LoadingScreenProgressBar value={uploadProgress / 100} />
      </div>
      <div slot='actions' className='flex justify-center w-[390px]'>
        <DesignItButton
          variant='text'
          disabled={uploadProgress == 100}
          onClick={innerCancelCallback}
        >
          Cancel Progress
        </DesignItButton>
      </div>
    </DesignItDialog>
  );
}

export default memo(UploadDialog);