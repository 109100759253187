import httpRequest from "../providers/httpRequestProvider";
import { endpoints } from "./endpoints";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { ApiError } from "./httpResponse";
import * as newAmplify from "@aws-amplify/auth";

export type UpdateBoltingDataFeedbackRequest = {
  updates: UpdateBoltingDataFeedback[];
};

export type UpdateBoltingDataFeedback = {
  PartitionKey: string;
  SortKey: string;
  Feedback: string;
};

export type UpdateBoltingDataFeedbackApiBody = {
  results: UpdateBoltingDataFeedback[];
  configName: string;
};

export const updateBoltingDataFeedback = async (
  data: UpdateBoltingDataFeedbackApiBody
): Promise<unknown> => {
  const { userId } = await newAmplify.getCurrentUser();
  const response = await httpRequest.post<unknown>(
    endpoints.UPDATE_BOLTING_DATA_FEEDBACK,
    { ...data, userId }
  );
  return response;
};

export const useUpdateBoltingDataFeedback = (
  options?: UseMutationOptions<
    unknown,
    ApiError,
    UpdateBoltingDataFeedbackApiBody
  >
) =>
  useMutation({
    mutationFn: (data: UpdateBoltingDataFeedbackApiBody) =>
      updateBoltingDataFeedback(data),
    ...options,
  });
