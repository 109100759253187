// apiHelper.tsx

import { getIdToken } from "../utils/credentialsHelper";
export interface ApiResponse {
  body: string | Record<string, unknown>;
  statusCode: number;
  Attributes?: string;
}

// Define a common function to handle API calls with default values for headers and body
async function apiCall(
  url: string,
  method: string,
  body: Record<string, string | unknown> | null = null,
  headers: Record<string, string> = {}
): Promise<ApiResponse> {
  try {
    const idToken = await getIdToken();

    if (!idToken) {
      throw new Error("No token found in session");
    }

    const authHeaders = {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
      ...headers,
    };

    const options: RequestInit = {
      method,
      headers: authHeaders,
    };
    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Network response was not ok: ${errorText}`);
    }

    const data: ApiResponse = await response.json();
    return data;
  } catch (error) {
    console.error(`API call to ${url} failed:`, error);
    throw error;
  }
}

// Define helper functions for common HTTP methods
export const apiHelper = {
  async get(url: string): Promise<ApiResponse> {
    return apiCall(url, "GET");
  },

  async post(
    url: string,
    body: Record<string, string | unknown>
  ): Promise<ApiResponse> {
    return apiCall(url, "POST", body, {
      "content-type": "application/json",
    });
  },

  async patch(
    url: string,
    body: Record<string, string | unknown>
  ): Promise<ApiResponse> {
    return apiCall(url, "PATCH", body, {
      "content-type": "application/json",
    });
  },

  // Add more methods like PUT, DELETE, etc. as needed
};
