import { useParams, useNavigate } from "react-router-dom";
import { useGetBoltingDataById } from "../../apis/useGetBoltingData";
import { RowViewComponent } from "./rowViewComponent";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";
import { ContentContainer } from "../../components/ContentContainer/ContentContainer";
import { ContentArea } from "../../components/ContentArea/ContentArea";
import { DesignItProgress } from "@design-it/react-library";

type BoltingDataDetailPageProps = {
  modelExists?: boolean;
};

export const BoltingDataDetailPage = ({
  modelExists = true,
}: BoltingDataDetailPageProps) => {
  const { id } = useParams();
  const { data, refetch, isLoading } = useGetBoltingDataById(id);
  const navigate = useNavigate();

  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, [setShowStepper]);

  return (
    <ContentContainer>
      <ContentArea>
        {isLoading ? (
          <div
            className="w-full h-full flex justify-center items-center flex-col"
            data-testid="loading"
          >
            <DesignItProgress indeterminate />
          </div>
        ) : (
          <div className="h-full" data-testid="content">
            <RowViewComponent
              onClose={() => navigate(-1)}
              updateBoltingOperationDataWithFeedback={() => refetch()}
              modelExists={Boolean(modelExists)}
              selectedRow={data}
            />
          </div>
        )}
      </ContentArea>
    </ContentContainer>
  );
};
