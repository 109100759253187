import { DesignItTabs, DesignItTab } from "@design-it/react-library";
import "./analysisPage.scss";
import { useState, useRef, useEffect } from "react";
import { PrepareAnalysis } from "./PrepareAnalysis";
import { ExecuteAnalysis } from "./ExecuteAnalysis";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useCSVContext } from "../../providers/CSVProvider";
import { UploadDropContainerProps } from "../../components/uploadDropContainer/uploadDropContainer";
import {
  uploadFile,
  fetchModelConfigs,
  verifyFile,
} from "../upload/uploadHelpers";
import { S3 } from "aws-sdk";
import { ModelConfigs, TabProps } from "./types";
import { ContentContainer } from "../../components/ContentContainer/ContentContainer";
import { ContentArea } from "../../components/ContentArea/ContentArea";
import { Stepper } from "../dashboard/Stepper";
import { useStepperContext } from "../../providers/StepperProvider";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";

type AnalysisTab = "prepare" | "execute";

export const AnalysisPage = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploadHandler, setUploadHandler] = useState<S3.ManagedUpload | null>(
    null
  );
  const { setProgress, setFileName, setLines } = useCSVContext();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [modelConfigs, setModelConfigs] = useState<ModelConfigs>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsActiveTab = searchParams.get("activeTab");
  const [hasValidModelConfig, setHasValidModelConfig] = useState<
    boolean | null
  >(null);

  const [toolId, setToolId] = useState<string>("");
  const [processId, setProcessId] = useState<string>("");
  const [configName, setConfigName] = useState<string>("");
  const [targetTorque, setTargetTorque] = useState<string>("");
  const [targetAngle, setTargetAngle] = useState<string | null>("");

  const { isExecuteTabAnalysis, setIsExecuteTabAnalysis } = useStepperContext();

  useEffect(() => {
    (async () => {
      const data = await fetchModelConfigs();

      setModelConfigs(data);
      const validConfig = Object.keys(data).length > 0;
      setHasValidModelConfig(validConfig);
    })();
  }, []);

  useEffect(() => {
    if (hasValidModelConfig === null) {
      return;
    }
    const params = new URLSearchParams();

    if (!searchParamsActiveTab) {
      params.set("activeTab", "prepare");
      navigate(`?${params.toString()}`);
    } else {
      if (searchParamsActiveTab === "execute" && !hasValidModelConfig) {
        params.set("activeTab", "prepare");
        navigate(`?${params.toString()}`);
      }

      if (
        searchParamsActiveTab !== "prepare" &&
        searchParamsActiveTab !== "execute"
      ) {
        params.set("activeTab", "prepare");
        navigate(`?${params.toString()}`);
      }
    }
  }, [hasValidModelConfig, searchParamsActiveTab]);

  const onTabClick = (
    event: React.MouseEvent<HTMLDesignItTabsElement, MouseEvent>
  ) => {
    let tabName: AnalysisTab = "prepare";

    const target = event.target as HTMLElement;
    if (target.textContent?.toLowerCase().includes("execute")) {
      tabName = "execute";
    }

    if (tabName === "execute" && !hasValidModelConfig) {
      return;
    }

    if (tabName === "execute") {
      setIsExecuteTabAnalysis(true);
    } else {
      setIsExecuteTabAnalysis(false);
    }
    searchParams.set("activeTab", tabName);
    setSearchParams(searchParams);
  };

  const handleUpload = uploadFile(
    file,
    setFileName,
    setUploadHandler,
    setShowUploadModal,
    setProgress,
    setLines
  );

  const uploadDropContainerProps: UploadDropContainerProps = {
    file,
    fileInputRef,
    setFile,
    verifyFile,
    uploadHandler,
    setShowUploadModal,
    showUploadModal,
  };

  const tabProps: TabProps = {
    handleUpload: handleUpload,
    showUploadModal: showUploadModal,
    file: file,
    modelConfigs: modelConfigs,
    toolId: toolId,
    setToolId: setToolId,
    configName: configName,
    setConfigName: setConfigName,
    processId: processId,
    setProcessId: setProcessId,
    targetTorque: targetTorque,
    setTargetTorque: setTargetTorque,
    targetAngle: targetAngle,
    setTargetAngle: setTargetAngle,
    uploadContainerProps: uploadDropContainerProps,
  };

  return (
    <ContentContainer>
      <ContentArea>
        <div className="h-full min-h-[700px]" id="analysis-container">
          <div className="px-5 h-[20%]">
            <Stepper
              steps={
                isExecuteTabAnalysis
                  ? MLFlowStepperLabelsExecuteTab
                  : MLFlowStepperLabelsAnalysisTab
              }
              currentStep={1}
              shouldShowStepper={true}
            />
          </div>
          <div className="h-[80%]">
            <div className="h-full disable-tab">
              <DesignItTabs
                onClick={event => onTabClick(event)}
                className="h-[10%]"
              >
                <DesignItTab
                  variant="secondary"
                  selected={
                    searchParamsActiveTab === "prepare" ? true : undefined
                  }
                >
                  Prepare analysis
                </DesignItTab>

                <DesignItTab
                  variant="secondary"
                  selected={
                    searchParamsActiveTab === "execute" ? true : undefined
                  }
                >
                  <span
                    className={
                      " " +
                      (hasValidModelConfig ? "" : "disabled text-[#7C7B89]")
                    }
                    data-testid="execute-analysis-tab-button"
                  >
                    Execute analysis
                  </span>
                </DesignItTab>
              </DesignItTabs>

              <div className="tabs h-[90%] rounded-b-xl">
                {searchParamsActiveTab === "prepare" && (
                  <PrepareAnalysis {...tabProps} />
                )}

                {searchParamsActiveTab === "execute" && (
                  <ExecuteAnalysis {...tabProps} />
                )}
              </div>
            </div>
          </div>
        </div>
      </ContentArea>
    </ContentContainer>
  );
};
