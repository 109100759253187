import { createContext, useContext, PropsWithChildren, useState } from "react";
import { BoltingData } from "../apis/types";

export type InitStateType = {
    data: BoltingData[];
    setData: (data: BoltingData[]) => void;
    currentRow: BoltingData | null;
    setCurrentRow: (row: BoltingData) => void;
};

const initState: InitStateType = {
  data: [],
  setData: () => null,
  currentRow: null,
  setCurrentRow: () => null,
};

const ResultsContext = createContext(initState);

export const useResultsContext = () => {
  const context = useContext(ResultsContext);

  return context;
};

export const ResultsProvider = ({
  children
}: PropsWithChildren) => {
  const [data, setData] = useState<BoltingData[]>(initState.data);
  const [currentRow, setCurrentRow] = useState<BoltingData | null>(initState.currentRow);

  return (
    <ResultsContext.Provider value={{ data, setData, currentRow, setCurrentRow }}>
      {children}
    </ResultsContext.Provider>
  );
};
