import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { isUserAuthenticated } from "../../utils/credentialsHelper";

export const ProtectedRoute: React.FC = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authStatus = await isUserAuthenticated();
        setIsAuthenticated(authStatus ?? false);
      } catch (error) {
        console.error("Error checking authentication:", error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
