import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DesignItTag } from "@design-it/react-library";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { format } from "date-fns";
import { BoltingData } from "../../apis/types";

import "../results/resultsTable.scss";
import { useNavigate } from "react-router-dom";

export enum FeedbackValues {
  NONE = "NONE",
  OK = "OK",
  NOK = "NOK",
}

type CustomHeaderProps = {
  icon: React.ReactElement;
  title: string;
};

type LabellingTableProps = {
  data: BoltingData[] | undefined;
  isLoading: boolean;
  className?: string;
};

export const LabellingTable = ({
  data,
  isLoading,
  className,
}: LabellingTableProps) => {
  const navigate = useNavigate();

  const CustomHeader = (props: CustomHeaderProps) => {
    return (
      <div className="custom-header">
        <span className="header-icon">{props.icon} </span>
        <span className="header-title">{props.title}</span>
      </div>
    );
  };

  /**
   * Since something's off with the AgGrid column definitions, we're using any[] here.
   * Need to be refactored to use ColDef[] or ColGroupDef[].
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const colDefs: any[] = [
    {
      field: "id",
      headerComponent: () => (
        <CustomHeader
          icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="ID"
        />
      ),
      flex: 4,
      cellClass: "custom-cell",
    },
    {
      field: "productId",
      headerComponent: () => (
        <CustomHeader
          icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="Product ID"
        />
      ),
      flex: 2,
      cellClass: "custom-cell",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "processId",
      headerComponent: () => (
        <CustomHeader
          icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="Process ID"
        />
      ),
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: "prgNr",
      headerComponent: () => (
        <CustomHeader
          icon={<Grid3x3Icon className="header-icon" fontSize="small" />}
          title="Cluster ID"
        />
      ),
      flex: 2,
      cellClass: "custom-cell",
    },
    {
      field: "status",
      headerComponent: () => (
        <CustomHeader
          icon={
            <CheckCircleOutlineIcon className="header-icon" fontSize="small" />
          }
          title="Bolting Tool Result"
        />
      ),
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: "createdDate",
      headerComponent: () => (
        <CustomHeader
          icon={<AccessAlarmsIcon className="header-icon" fontSize="small" />}
          title="Timestamp Result"
        />
      ),
      cellRenderer: (props: CustomCellRendererProps) => {
        return format(new Date(props.value), "dd.MM.yyyy | HH:mm");
      },
      flex: 3,
      cellClass: "custom-cell",
    },
    {
      field: "feedback",
      cellRenderer: (props: CustomCellRendererProps) => {
        return (
          <DesignItTag
            onClick={() => navigate(`/labelling/${props.data.id}`)}
            key={props.value}
            className="ics-green-light"
            variant={(() => {
              if (props.value === FeedbackValues.NOK) {
                return "error";
              }
              if (props.value === FeedbackValues.OK) {
                return "success";
              }
              return "surface";
            })()}
            icon-name={(() => {
              if (props.value === FeedbackValues.NOK) {
                return "do_not_disturb_on";
              }
              if (props.value === FeedbackValues.OK) {
                return "check_circle";
              }
              return "info";
            })()}
            icon-position="first"
          >
            {(() => {
              if (props.value === FeedbackValues.NOK) {
                return "anomaly";
              }
              if (props.value === FeedbackValues.OK) {
                return "no anomaly";
              }
              return "wanna check this";
            })()}
          </DesignItTag>
        );
      },
      cellClass: "custom-cell",
    },
    {
      field: "anomalyDetected",
      headerComponent: () => (
        <CustomHeader
          icon={<HdrAutoIcon className="header-icon" fontSize="small" />}
          title="AI Result"
        />
      ),
      flex: 3,
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value === "yes") {
          return (
            <DesignItTag
              className="ics-green-light"
              variant="error"
              icon-name="do_not_disturb_on"
              icon-position="first"
            >
              anomaly
            </DesignItTag>
          );
        }

        if (props.value === "no") {
          return (
            <DesignItTag
              className="ics-green-light"
              variant="success"
              icon-name="check_circle"
              icon-position="first"
            >
              no anomaly
            </DesignItTag>
          );
        }
      },
      cellClass: "custom-cell",
    },
  ];

  return (
    <div className={`table-layout  ${className} `}>
      <AgGridReact
        className="grid-layout"
        rowData={data}
        loading={isLoading}
        columnDefs={colDefs}
        rowClass="table-row"
      />
    </div>
  );
};
