import { useNavigate, useSearchParams } from "react-router-dom";
import { ResultsTable } from "./ResultsTable";
import { useGetBoltingDataWithAnomalies } from "../../apis/useGetBoltingData";
import { useStepperContext } from "../../providers/StepperProvider";
import { DesignItButton, DesignItProgress } from "@design-it/react-library";
import { downloadPdf } from "../../utils/reportHelpers";
import { ContentContainer } from "../../components/ContentContainer/ContentContainer";
import { ContentArea } from "../../components/ContentArea/ContentArea";
import { Stepper } from "../dashboard/Stepper";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";

export const ResultsPage = () => {
  const { isExecuteTabAnalysis } = useStepperContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("fileName");

  const { data, isLoading } = useGetBoltingDataWithAnomalies();

  return (
    <ContentContainer>
      <ContentArea>
        {isLoading && !data ? (
          <div className="w-full h-full flex justify-center items-center flex-col">
            <DesignItProgress indeterminate />
          </div>
        ) : (
          <>
            <div className="h-[15%] ">
              <div className="table-title">Results</div>
              <div className="table-subtitle">
                Based upon your given feedback on the previously trained data,
                we generated some results for you. You can download them.
              </div>
            </div>
            <div className="h-[85%]">
              <div className="px-5 h-[20%]">
                <Stepper
                  steps={
                    isExecuteTabAnalysis
                      ? MLFlowStepperLabelsExecuteTab
                      : MLFlowStepperLabelsAnalysisTab
                  }
                  currentStep={3}
                  shouldShowStepper={true}
                />{" "}
              </div>

              <div className="h-[80%]">
                <ResultsTable
                  className="h-full"
                  data={data!}
                  isLoading={isLoading}
                  onArrowClick={row => navigate(`/results/${row.id}`)}
                />
              </div>
            </div>
          </>
        )}
      </ContentArea>
      <div className="footer-buttons h-[5%] pr-5">
        <DesignItButton
          variant="outlined"
          onClick={() =>
            navigate(`/data-check?fileName=${fileName}&lastStep=results`)
          }
        >
          <span>Go back</span>
        </DesignItButton>

        <DesignItButton variant="filled-tonal" onClick={() => downloadPdf()}>
          <span>Download</span>
        </DesignItButton>
      </div>
    </ContentContainer>
  );
};
