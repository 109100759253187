/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { convertEpochToFormattedTime } from "../../utils/uploadLimitHelpers";
import "./index.scss";
import { DesignItTag } from "@design-it/react-library";
import { DesignItProgress } from "@design-it/react-library";
import { BoltingData } from "../../apis/types";

export interface Column {
	name: string;
	icon: React.ReactNode;
}
export interface Row {
	created_date: number;
	angle_in_deg: string;
	anomaly_detected: string;
	id: string;
	prg_nr: string;
	process_id: string;
	product_id: string;
	reconstructed_torque: string;
	status: string;
	time_in_sec: string;
	torque_in_nm: string;
	"product_id#process_id": string;
	feedback: string;
}

interface DataTable {
	hovering: boolean;
	columns: Column[];
	row: BoltingData[];
	onRowClick: (row: BoltingData) => void | undefined;
	boltingOperationDataSize?: number;
	page: number;
	rowsPerPage: number;
	setPage: (newPage: number) => void | undefined;
	setRowsPerPage: (newRowsPerPage: number) => void | undefined;
	loadboltingOperationData: boolean;
	setloadboltingOperationData: (set: boolean) => void;
	modelExists?: boolean;
}

export enum FeedbackValues {
	NONE = "NONE",
	OK = "OK",
	NOK = "NOK",
}

export default function BoltingOperationTable({ modelExists = false, ...props }: DataTable) {
  const [visibleRows, setVisisbleRows] = React.useState<BoltingData[]>([]);
  const handleClick = (row: BoltingData) => {
    if (props.onRowClick) {
      props.onRowClick(row);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    const endIndex = (newPage + 1) * props.rowsPerPage;

    if (
      endIndex > props.row.length &&
			props.row.length <
				(props.boltingOperationDataSize || Number.POSITIVE_INFINITY)
    ) {
      props.setloadboltingOperationData(true);
    }

    props.setPage(newPage);
  };

  React.useEffect(() => {
    const endIndex = (props.page + 1) * props.rowsPerPage;

    if (
      endIndex > props.row.length &&
			props.row.length <
				(props.boltingOperationDataSize || Number.POSITIVE_INFINITY)
    ) {
      props.setloadboltingOperationData(true);
    }
  }, [props.row.length]);

  React.useEffect(() => {
    const temp_rows = props.row.slice(
      props.page * props.rowsPerPage,
      props.page * props.rowsPerPage + props.rowsPerPage
    );

    setVisisbleRows(temp_rows);
  }, [props.row, props.page, props.rowsPerPage]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    props.setRowsPerPage(rowsPerPage);

    const totalDataSize = props.boltingOperationDataSize ?? 0;
    const currentPageStartIndex = props.page * rowsPerPage;
    const newPageCount = Math.floor(totalDataSize / rowsPerPage);

    if (currentPageStartIndex < totalDataSize) {
      const endIndex = (props.page + 1) * rowsPerPage;

      if (
        endIndex > props.row.length &&
				props.row.length < totalDataSize
      ) {
        props.setloadboltingOperationData(true);
      }
    } else {
      const newPage = Math.max(0, newPageCount - 1);

      props.setPage(newPage);
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "auto" }}>
      <TableContainer className={`${props.hovering ? "h-[50vh]" : "h-auto"}`}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {props.columns.map((key: Column, index: number) => (
                <TableCell
                  key={`${index}_${key.name}`}
                  className='column-name-border'
                >
                  <div className='icon-name-wrapper'>
                    {key.icon}
                    <span>{key.name}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row: BoltingData, index: number) => (
              <TableRow
                key={`${row["productId#processId"]}_${index}`}
                hover={props.hovering ? true : false}
                onClick={() => handleClick(row)}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  cursor: "pointer",
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                  className='column-border'
                >
                  {row.productId +
											"#" +
											row.processId}
                </TableCell>
                <TableCell
                  align='center'
                  className='column-border'
                >
                  {row.prgNr}
                </TableCell>
                <TableCell
                  align='center'
                  className='scrollable-cell column-border'
                >
                  {row.status}
                </TableCell>
                <TableCell
                  align='center'
                  className='column-border'
                >
                  {convertEpochToFormattedTime(
                    row.createdDate
                  )}
                </TableCell>
                {
                  !modelExists && (
                    <TableCell
                      align='center'
                      className='column-border'
                    >
                      <DesignItTag
                        key={row.feedback}
                        className='ics-green-light'
                        variant={
                          row.feedback ===
														FeedbackValues.NOK
                            ? "error"
                            : row.feedback ===
															FeedbackValues.OK
                              ? "success"
                              : "surface"
                        }
                        icon-name={
                          row.feedback ===
														FeedbackValues.NOK
                            ? "do_not_disturb_on"
                            : row.feedback ===
															FeedbackValues.OK
                              ? "check_circle"
                              : "info"
                        }
                        icon-position='first'
                      >
                        {row.feedback ===
													FeedbackValues.NOK
                          ? "anomaly"
                          : row.feedback ===
														FeedbackValues.OK
                            ? "no anomaly"
                            : "wanna check this"}
                      </DesignItTag>
                    </TableCell>		
                  )
                }
                <TableCell
                  align='center'
                  className='column-border'
                >
                  {row.anomalyDetected === "yes" ? (
                    <DesignItTag
                      className='ics-green-light'
                      variant='error'
                      icon-name='do_not_disturb_on'
                      icon-position='first'
                    >
											anomaly
                    </DesignItTag>
                  ) : row.anomalyDetected === "no" ? (
                    <DesignItTag
                      className='ics-green-light'
                      variant='success'
                      icon-name='check_circle'
                      icon-position='first'
                    >
											no anomaly
                    </DesignItTag>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
            {props.loadboltingOperationData && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  style={{ textAlign: "center" }}
                >
                  <DesignItProgress
                    indeterminate
                  ></DesignItProgress>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.row.length > 5 && (
        <TablePagination
          style={{
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
          rowsPerPageOptions={[10, 20, 30, 50]}
          component='div'
          count={props.boltingOperationDataSize || 0}
          rowsPerPage={props.rowsPerPage}
          page={props.page || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
