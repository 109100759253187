import { AgGridReact, CustomCellRendererProps } from "@ag-grid-community/react";
import {
  GridReadyEvent,
  IDatasource,
  ModuleRegistry,
} from "@ag-grid-community/core";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DesignItProgress } from "@design-it/react-library";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { format } from "date-fns";
import { getAllBoltingData } from "../../apis/useGetBoltingData";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { getCurrentUser } from "@aws-amplify/auth";
import { AnomalyTagComponent } from "../../components/AnomalyTag/AnomalyTag";
import "./resultsTable.scss";
import { useCallback, useMemo } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  CustomHeaderBuilder,
  ResultsTableProps,
} from "../../components/table/CustomHeader";
ModuleRegistry.registerModules([InfiniteRowModelModule]);

export const ResultsTable = ({
  onArrowClick,
  isLoading,
  className,
  hideArrow = false,
}: ResultsTableProps) => {
  const iconStyle = useMemo(() => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "22px",
      cursor: "pointer",
    };
  }, []);

  const anomalyCellRenderer = useCallback((props: CustomCellRendererProps) => {
    if (props.value !== undefined) {
      return <AnomalyTagComponent value={props.value} />;
    }
  }, []);

  const arrowCellRenderer = useCallback(
    (props: CustomCellRendererProps) => (
      <ArrowForwardRoundedIcon
        style={iconStyle}
        onClick={() => onArrowClick?.(props.data)}
      />
    ),
    [onArrowClick, iconStyle]
  );

  const idCellRenderer = useCallback((props: ICellRendererParams) => {
    if (props.value !== undefined) {
      return `${props.data.productId}#${props.data.processId}`;
    } else {
      return (
        <DesignItProgress
          style={{ "--md-circular-progress-size": "30px" }}
          indeterminate
        />
      );
    }
  }, []);

  const getColDefs = () => {
    /**
     * Since something's off with the AgGrid column definitions, we're using any[] here.
     * Need to be refactored to use ColDef[] or ColGroupDef[].
     */
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const colDefs: any[] = [
      {
        field: "id",
        headerComponent: CustomHeaderBuilder(
          "ID",
          <Grid3x3Icon className="header-icon" fontSize="small" />
        ),
        flex: 4,
        cellClass: "custom-cell",
        valueGetter: "node.id",
        cellRenderer: idCellRenderer,
      },
      {
        field: "prgNr",
        headerComponent: CustomHeaderBuilder(
          "Cluster ID",
          <Grid3x3Icon className="header-icon" fontSize="small" />
        ),
        flex: 2,
        cellClass: "custom-cell",
      },
      {
        field: "status",
        headerComponent: CustomHeaderBuilder(
          "Bolting Tool Result",
          <CheckCircleOutlineIcon className="header-icon" fontSize="small" />
        ),
        flex: 3,
        cellClass: "custom-cell",
      },
      {
        field: "createdDate",
        valueGetter: "node.id",

        headerComponent: CustomHeaderBuilder(
          "Timestamp Result",
          <AccessAlarmsIcon className="header-icon" fontSize="small" />
        ),
        cellRenderer: (props: CustomCellRendererProps) => {
          if (props.value !== undefined) {
            return format(
              new Date(props.data.createdDate),
              "dd.MM.yyyy | HH:mm"
            );
          }
        },
        flex: 3,
        cellClass: "custom-cell",
      },
      {
        field: "anomalyDetected",
        headerComponent: CustomHeaderBuilder(
          "AI Result",
          <HdrAutoIcon className="header-icon" fontSize="small" />
        ),
        flex: 3,
        cellRenderer: anomalyCellRenderer,
        cellClass: "custom-cell",
      },
    ];

    if (!hideArrow) {
      colDefs.push({
        flex: 1,
        cellRenderer: arrowCellRenderer,
        cellClass: "custom-cell",
      });
    }

    return colDefs;
  };

  const getRows = async (params: any) => {
    const { userId } = await getCurrentUser();

    const pageSize = 20;
    const pageNumber = Math.floor(params.startRow / pageSize) + 1;

    const cacheKey = `boltingData_${userId}_${pageNumber}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      if (cachedData !== "[]") {
        const data = JSON.parse(cachedData);
        let lastRow = undefined;
        if (data.length < pageSize) {
          lastRow = params.startRow + data.length;
        }
        params.successCallback(data, lastRow);
        return;
      }
    }

    getAllBoltingData(
      userId,
      { anomaly_detected: "yes" },
      pageSize,
      pageNumber
    ).then(data => {
      const storageSize = JSON.stringify(localStorage).length;
      const dataSize = JSON.stringify(data).length;

      const remainingSpace = 5242880 - storageSize;

      if (dataSize < remainingSpace) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      } else {
        console.warn("LocalStorage is full or nearly full. Cannot cache data.");
      }

      let lastRow = undefined;
      if (data.length < pageSize) {
        lastRow = params.startRow + data.length;
      }

      params.successCallback(data, lastRow);
    });
  };

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: getRows,
    };
    params.api.setGridOption("datasource", dataSource);
  }, []);

  return (
    <div className="result-layout h-full">
      <div className={`table-layout ${className}`}>
        <AgGridReact
          loading={isLoading}
          columnDefs={getColDefs()}
          rowClass="table-row"
          rowBuffer={3}
          rowModelType={"infinite"}
          cacheBlockSize={20}
          cacheOverflowSize={10}
          maxConcurrentDatasourceRequests={5}
          infiniteInitialRowCount={1000}
          maxBlocksInCache={200}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};
