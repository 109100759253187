import CombinedGraph from "./combinedGraph";
import { BoltingData } from "../../apis/types";

interface DetailedGrapViewProps {
	row: BoltingData;
}

export default function DetailedGrapView(props: DetailedGrapViewProps) {
  return (
    <CombinedGraph row={props.row} />
  );
}
