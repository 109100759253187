import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { useGetBoltingData } from "../../apis/useGetBoltingData";
import { LabellingTable } from "./LabellingTable";
import { DesignItButton, DesignItDialog } from "@design-it/react-library";
import { BoltingData } from "../../apis/types";
import {
  UpdateBoltingDataFeedbackApiBody,
  useUpdateBoltingDataFeedback,
} from "../../apis/useSaveBoltingDataFeedback";
import { useMemo, useState } from "react";
import { useStepperContext } from "../../providers/StepperProvider";
import { verifyModelConfig } from "../upload/uploadHelpers";
import "./LabellingPage.scss";
import { ContentContainer } from "../../components/ContentContainer/ContentContainer";
import { ContentArea } from "../../components/ContentArea/ContentArea";
import { Stepper } from "../dashboard/Stepper";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";

export const LabellingPage = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetBoltingData();
  const { mutate: updateFeedback } = useUpdateBoltingDataFeedback({
    onSuccess: () => {
      setTimeout(() => {
        navigate("/analysis");
      }, 10000);
    },
  });
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("fileName");
  const configName = searchParams.get("configName");

  const [showDialog, setShowDialog] = useState(false);
  const { isExecuteTabAnalysis } = useStepperContext();

  const feedbackData: UpdateBoltingDataFeedbackApiBody = useMemo(() => {
    const feedbackResults =
      data?.map((item: BoltingData) => ({
        PartitionKey: item["productId#processId"],
        SortKey: item.createdDate.toString(),
        Feedback: item.feedback,
        loss: item.loss,
      })) ?? [];

    return {
      results: feedbackResults,
      configName: configName ?? "config1",
    };
  }, [data, configName]);

  const saveAndContinueDisabled =
    data?.findIndex((d: BoltingData) => d.feedback === "NONE") != -1;

  const onSaveAndContinueClick = () => {
    if (!saveAndContinueDisabled) {
      setShowDialog(true);
      updateFeedback(feedbackData);
      verifyModelConfig(configName ?? "config1");
    }
  };

  if (!fileName) {
    return <Navigate to={"/analysis"} />;
  }

  return (
    <ContentContainer>
      <ContentArea>
        <div className="table-title">Your feedback is required.</div>
        <div className="table-subtitle">
          Please provide feedback to all predictions. The better the feedback, the better the analysis in the end.
        </div>
        <div className={showDialog ? "blur-background" : "" + " h-[80%]"}>
          <div className="px-5 h-[20%]">
            <Stepper
              steps={
                isExecuteTabAnalysis
                  ? MLFlowStepperLabelsExecuteTab
                  : MLFlowStepperLabelsAnalysisTab
              }
              currentStep={3}
              shouldShowStepper={true}
            />{" "}
          </div>
          <div className="h-[80%]">
            <LabellingTable
              data={data}
              isLoading={isLoading}
              className="h-full"
            />
          </div>
        </div>
      </ContentArea>
      <div className="footer-buttons h-[5%]">
        <DesignItButton
          onClick={() => navigate(`/data-check?fileName=${fileName}`)}
        >
          Go Back
        </DesignItButton>

        <DesignItButton
          onClick={onSaveAndContinueClick}
          disabled={Boolean(saveAndContinueDisabled)}
        >
          Submit feedback
        </DesignItButton>
        <DesignItDialog open={showDialog} maxWidth="420px">
          <div slot="headline" className="flex justify-center pt-24">
            Thank you for your feedback.
          </div>
          <form
            slot="content"
            method="dialog"
            id="form"
            className="flex justify-center items-center pb-36 p-12 flex-col"
          >
            <p className="justify-self-center">
              In a moment, you’ll be able to either use the trained AI model for
              analysis or seamlessly train a new one.
            </p>
          </form>
        </DesignItDialog>
      </div>
    </ContentContainer>
  );
};
