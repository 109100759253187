import * as React from "react";
import { DesignItInput } from "@design-it/react-library";
import { styled } from "@mui/system";

const InputComponent = styled(DesignItInput)({});

/**  Create proper interface..
 * DesignIt currently does not have a proper interface for the input component.
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export default function BoltItInput(props: any) {
  return <InputComponent {...props} />;
}
