import { BoltingData, DbBoltingData } from "../../apis/types";

type CustomHeaderProps = {
  icon: React.ReactElement;
  title: string;
};

const CustomHeader = (props: CustomHeaderProps) => {
  return (
    <div className="custom-header">
      <span className="header-icon">{props.icon} </span>
      <span className="header-title">{props.title}</span>
    </div>
  );
};

export type ResultsTableProps = {
  onArrowClick?: (row: DbBoltingData) => void;
  data?: BoltingData[];
  isLoading: boolean;
  className?: string;
  hideArrow?: boolean;
};

export const CustomHeaderBuilder = (
  title: string,
  icon: React.ReactElement
) => {
  return function CustomHeaderComponent(props: unknown) {
    return (
      <CustomHeader
        {...(props as CustomHeaderProps)}
        title={title}
        icon={icon}
      />
    );
  };
};
