import { getCurrentUser } from "@aws-amplify/auth";
import { apiHelper } from "../apis/apiHelper";

export async function downloadPdf() {
  const user_id = (await getCurrentUser())["userId"];

  const response = await apiHelper.post(
    process.env.REACT_APP_REST_API_ROOT_URL + "/get-pdf",
    { user_id }
  );

  // Convert Base64 to a Blob
  const byteCharacters = atob(response.body as string);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // create a link and trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `bolt_it_report_${new Date().toISOString()}.pdf`;
  link.click();
}
