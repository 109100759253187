import {
  DesignItSideNavigation,
  DesignItSideNavigationItem,
} from "@design-it/react-library";

export function SideMenu() {
  return (
    <div className='rounded-xl h-full  px-4'>
      <DesignItSideNavigation className='w-full'>
        <DesignItSideNavigationItem
          iconName='drive_folder_upload'
          active
          link='/'
          className='side-navigation-item'
        >
					Home
        </DesignItSideNavigationItem>
      </DesignItSideNavigation>
    </div>
  );
}
