import React from "react";
import { DesignItProgress } from "@design-it/react-library";
import "./style.scss";
import { Box, Typography } from "@mui/material";

interface LoadingScreenProgressBarProps {
  value: number;
}

export default function LoadingScreenProgressBar(
  props: LoadingScreenProgressBarProps
) {
  return (
    <DesignItProgress
      className="loading-screen-progress-bar"
      value={props.value}
    >
      <Box
        top={"5.7rem"}
        left={0}
        bottom={0}
        right={0}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="inner-percentage-container"
      >
        <Typography
          variant="h6"
          component="div"
          color="white"
          fontWeight={100}
          className="inner-percentage"
        >
          {`${Math.round(props.value * 99)}%`}
        </Typography>
      </Box>
    </DesignItProgress>
  );
}
