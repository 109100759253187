import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "./index.scss";
import { ResultsProvider } from "./providers/resultsProvider";
import { ReactQueryProvider } from "./providers/ReactQueryProvider";
import { StepperProvider } from "./providers/StepperProvider";
import { CSVProvider } from './providers/CSVProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import { AutoCompleteTheme } from "./components/customDropdown/AutoCompleteTheme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactQueryProvider>
        <ResultsProvider>
          <CSVProvider>
            <Router>
              <StepperProvider>
                <ThemeProvider theme={AutoCompleteTheme}>
                  <App />
                </ThemeProvider>
              </StepperProvider>
            </Router>
          </CSVProvider>
        </ResultsProvider>
      </ReactQueryProvider>
    </Provider>
  </React.StrictMode>
);	
